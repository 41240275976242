import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import Logo from '../images/logo.png';
import Exterior from '../images/exterior.jpg';
import grayhawkBPS from '../images/grayhawk-bps.pdf';
import Edgelogo from '../images/edgelogo.jpg';

import img1 from '../images/interior/1.jpg';
import img2 from '../images/interior/2.jpg';
import img3 from '../images/interior/3.jpg';
import img4 from '../images/interior/4.jpg';
import img5 from '../images/interior/5.jpg';
import img6 from '../images/interior/6.jpg';
import img7 from '../images/interior/7.jpg';
import img8 from '../images/interior/8.jpg';
import img9 from '../images/interior/9.jpg';
import img10 from '../images/interior/10.jpg';
import img11 from '../images/interior/11.jpg';
import img12 from '../images/interior/12.jpg';
import img13 from '../images/interior/13.jpg';
import img14 from '../images/interior/14.jpg';

const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14];

function Gallery() {
  return (
    <div className="image-grid">
      {images.map((img, index) => (
        <div className="image-container" key={index}>
            <img src={img} alt={`Interior ${index + 1}`} />
        </div>
      ))}
    </div>
  );
}

function Home() {
  const title = 'Grayhawk Estates in Sylvan Lake by Edge Homes';

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className="container-fluid hero-banner">
        <div className="py-5 text-center">
          <div className="col-lg-12"><img src={Logo} alt="Logo" className="hero-logo" /></div>
          <h2>Right Size Your Life<br/>Modern Craftsman Charm</h2>
          <div className="col-lg-6 mx-auto">
            <p className="lead mb-4">
            Downsize Without Compromise: Spacious Bungalow Duplexes<br/>
            Your Next Chapter Begins<br/>
            Perfect for Couples, Small Families, or Empty Nesters
            </p>
          </div>
        </div>
      </main>
      <section className="container-fluid contact-details">
        <div className="row">
          <div className="col-lg-12">
            <h3><img src={Edgelogo} alt="Edge Homes" className="edgelogo"></img><b>CALL NOW</b> TO ARRANGE A VIEWING OF THESE SPECTACULAR HOMES:<br/>Shawn 403-348-7299</h3>
          </div>
        </div>
      </section>
      <section className="container-fluid property-details">
        <div className="row"> {/* Ensure that divs are wrapped in a .row */}
          <div className="col-lg-6 property"> {/* Added shadow and rounded classes */}
            <p>
              <h3>99 Gray Close</h3><br/>
              952 sq. ft. bungalow duplex<br/>
              1 Bedroom<br/>
              1.5 bathrooms<br/>
              Located on a large pie lot<br/>
              <h2>$364,900*</h2><sub>* including lot and GST</sub>
            </p>
          </div>
          <div className="col-lg-6 property"> {/* Added shadow and rounded classes */}
            <p>
              <h3>101 Gray Close</h3><br/>
              952 sq. ft. bungalow duplex<br/>
              1 Bedroom<br/>
              1.5 bathrooms<br/>
              Located on a large pie lot<br/>
              <h2>$364,900*</h2><sub>* including lot and GST</sub>
            </p>
          </div>
        </div>
      </section>
      <section className="container-fluid property-features">
        <div className="row"> {/* Ensure that divs are wrapped in a .row */}
        <div className="col-lg-8">
          <img src={Exterior} alt="Logo" className="exterior-image" />
        </div>
          <div className="col-lg-4"> {/* Added shadow and rounded classes */}
            <p>
              <h3>FEATURES</h3>
              <a href={grayhawkBPS} className="btn btn-primary btn-sm" download="grayhawk-bps.pdf">Download Blueprints</a><br/><br/>
              <ul>
                <li>Pie Lot</li>
                <li>9ft ceilings main floor and basement</li>
                <li>Full height upgraded kitchen cabinets</li>
                <li>Under-cabinet lighting</li>
                <li>Soft close drawers</li>
                <li>All Quartz Countertops</li>
                <li>Full height tiled kitchen backsplash</li>
                <li>Luxury tile ensuite backsplash</li>
                <li>Extra large walk-in and fully built-in master suite closet</li>
                <li>Luxury flooring throughout</li>
                <li>Optional basement development</li>
                <li>Optional detached garage</li>
                <li>Covered by Alberta New Home Warranty</li>
                <li>Sylvan Lake’s highest quality builder</li>
              </ul>
            </p>
          </div>
        </div>
      </section>
      <section className="container-fluid property-gallery">
        <div className="row"> {/* Ensure that divs are wrapped in a .row */}
          <div className="col-lg-12">
            <h2>Interior Gallery</h2>
            {Gallery()}
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
